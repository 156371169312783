<template>

    <div>

        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">
                <b-breadcrumb-item :to="{ name: 'dashboard-analytics' }">
                    <feather-icon class="align-text-top" icon="HomeIcon" size="15"/>
                </b-breadcrumb-item>
                <b-breadcrumb-item active>{{$t('label_notifications')}}</b-breadcrumb-item>
            </div>
        </div>


        <b-card
                no-body
                class="mb-0"
        >
            <b-card-header class="pb-50">
                <h5>{{ $t('label_notifications') }}</h5>
            </b-card-header>

            <div class="m-2">

                <b-row>

                    <b-col
                            cols="12"
                            md="5"
                            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >

                        <v-select
                                v-model="tableData.perPage"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="tableData.perPageOptions"
                                :clearable="false"
                                :searchable="false"
                                class="per-page-selector d-inline-block mx-50"
                        />
                    </b-col>

                </b-row>

            </div>

            <!--***********************************************************TABLE*********************************************-->

            <div class="px-2">
                <!--sticky-header-->
                <b-table
                        ref="table"
                        class="position-relative transited-table"
                        :busy="isBusy"
                        no-border-collapse
                        :items="tableItems"
                        responsive
                        :fields="columnsDefs"
                        primary-key="id"
                        :sort-by.sync="tableData.sortBy"
                        no-local-sorting
                        :sort-desc.sync="tableData.sortDesc"
                        show-empty
                        :tbody-transition-props="{ name: 'flip-list'}"
                        :empty-text="$t('label_no_table_data')"

                >

                    <template #head()="data">
                        <span class="text-secondary">{{ $t(data.label).toUpperCase() }}</span>
                    </template>

                    <template #cell(icon)="data">
                        <!--{{data.item.background + ' ' +data.item.icon}}-->
                        <b-avatar
                                :variant="`light-primary`"
                                size="35"
                        >
                            <!--:icon="icon"-->
                            <feather-icon
                                    size="19"
                                    :icon="data.item.icon"
                            />
                        </b-avatar>
                    </template>

                    <template #cell(type_name)="data">

                         <div style="min-width: 85px">{{solveSourceName(data.item)}}</div>

                    </template>
                    <template #cell(title)="data">
<span>{{data.item.title}}</span>
<p><small>{{data.item.message}}</small></p>


                    </template>
                    <template #cell(actions)="data">
                        <b-button v-if="data.item[data.item.type_name]" :to="solveRedirect(data.item)" variant="outline-primary" size="sm" >
                            {{$t('label_view')}}</b-button>
                    </template>

                    <template #cell(user_name)="data">
                        {{data.item.user? data.item.user.name : ''}}
                    </template>

                </b-table>
                <div class="mx-2 mb-2">
                    <b-row>

                        <b-col
                                cols="12"
                                sm="6"
                                class="d-flex align-items-center justify-content-center justify-content-sm-start"
                        >
                            <span class="text-muted">{{ $t('label_showed') + ' '+ $t('label_from').toLowerCase() + ' ' + dataMeta.from + ' ' + $t('label_to_date') + ' ' + dataMeta.to + ' ' + $t('label_of') + ' ' + dataMeta.of }} </span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col
                                cols="12"
                                sm="6"
                                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                        >

                            <b-pagination
                                    v-model="tableData.currentPage"
                                    :total-rows="tableTotal"
                                    :per-page="tableData.perPage"
                                    first-number
                                    last-number
                                    class="mb-0 mt-1 mt-sm-0"
                                    prev-class="prev-item"
                                    next-class="next-item"
                            >
                                <template #prev-text>
                                    <feather-icon
                                            icon="ChevronLeftIcon"
                                            size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                            icon="ChevronRightIcon"
                                            size="18"
                                    />
                                </template>
                            </b-pagination>

                        </b-col>

                    </b-row>
                </div>
            </div>


        </b-card>


    </div>
</template>

<script>

    import {
        BCard, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
        BBadge, BDropdown, BDropdownItem, BPagination, BCardBody, BCardHeader, BFormCheckbox, BTooltip
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'

    export default {
        components: {
            BCard,

            BFormInput,
            BButton,
            BTable,
            BMedia,
            BAvatar,
            BLink,
            BBadge,
            BDropdown,
            BDropdownItem,
            BPagination,
            BCardBody,
            BFormCheckbox,
            BCardHeader,
            BTooltip,
            vSelect,
        },

        data() {
            return {
                MODULE_PREFIX: 'user_notifications',

                columnsDefs: [

                    {label: '', key: 'icon',  sortable: false},
                    {label: 'label_source', key: 'type_name',  sortable: false},
                    {label: 'label_title',  key: 'title', sortable: false},
                    {label: 'label_creating_date',  key: 'postedtime',formatter: function (v) {
                            return formatDate(v, 'DD.MM.YYYY HH:mm', 'unix');
                        }, sortable: false},
                    {label: 'label_user', key: 'user_name', sortable: false},
                    {label: 'label_action', key: 'actions', sortable: false},
                ],

                editedItem: false,

                isBusy: false,
                tableTotal: 0,
                tableItems: [],

                tableData: {
                    currentPage: 1,
                    perPage: 25,
                    searchQuery: '',
                    sortBy: 'id',
                    sortDesc: true,
                    perPageOptions: [10, 50, 100, 200],
                },

            }
        },

        watch: {
            tableData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function () {
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    }, 100);
                },
                deep: true
            },

        },

        created() {
            this.columnsDefs = this.columnsDefs.filter((item)=>{
                if(item.key == 'user' && this.authUser().role != 'admin'){
                    return false;
                } else {
                    return true
                }
            });
            this.refreshDataTable();
            document.getElementsByTagName('body')[0].click();
        },
        computed: {
            dataMeta: (context) => {
                return {
                    from: context.tableData.perPage * (context.tableData.currentPage - 1) + (context.tableItems.length ? 1 : 0),
                    to: context.tableData.perPage * (context.tableData.currentPage - 1) + context.tableItems.length,
                    of: context.tableTotal,
                }
            },
        },

        methods: {

            refreshDataTable: function () {
                this.isBusy = true;
                this.async('get', '/' + this.MODULE_PREFIX , {
                    params: {

                        length: this.tableData.perPage,
                        start: (this.tableData.currentPage - 1) * this.tableData.perPage,
                        search: this.tableData.searchQuery,
                        sort_by: this.tableData.sortBy,
                        sort_desc: this.tableData.sortDesc ? true : null


                    }
                }, function (resp) {

                    this.tableItems = resp.data.items;
                    this.tableTotal = resp.data.total;
                    this.isBusy = false;
                });
            },

            solveRedirect(item){
                let source_table = item.source_table; let module_id = item.item_id;

                let params = {};
                let routeName = {};

                switch(source_table){
                    case 'com_agreement_tasks': routeName = 'agreement_task_details'; params.id = item.deal_task.id;  break;
                    case 'com_agreement_notes': routeName = 'agreement_tabs';params.alias = 'note'; params.id = module_id; break;
                    case 'com_agreement_task_comments': routeName = 'deal_task_details'; params.id = module_id; break;
                    case 'com_agreements': routeName = 'deal-details'; params.id = module_id; break;
                    case 'com_mails':
                        if(item.mail){
                            routeName = 'mails_' + item.mail.mail_type;
                        }
                        break;
                }

                return   { name:routeName, params:params};
            },
            solveSourceName(item){
                let name = '';
                let source = item[item.type_name];
                if(source){

                    switch(item.type_name){
                        case 'deal_task':
                        case 'mail':
                        case 'deal_note':
                        case 'deal_task_comment':
                        case 'deal':
                            if(item.type_name == 'deal_task' && item.deal_task){

                                    if(item.deal_task.type == 'contract_registry') {
                                        name = item.deal_task.contract_registry.contract_subject;
                                        break;
                                    } else if (item.deal_task.type == 'free'){
                                        name = '--';
                                        break;
                                    }


                            }
                            if(item.deal){
                                name = item.deal.number;
                            } else if(item.agreement){
                                name = item.agreement.deal_number;
                            } else {
                                name = '';
                            }
                            break;
                    }
                } else {
                    if(item.type_name == 'google_api') {
                        name = '';
                    } else {
                        name = '--'+this.$t('label_activity_procurations_delete')+'--';
                    }

                }
                return name;
            }

        },


    }
</script>

